export class MemberShip {

    UserToken: string;
    OrganisationID: number;
    MemberID: number; 
    InvoiceNumber: string;
    MainProductID: number; 
    SpouseProductID: number;
    Junior1ProductID: number; 
    Junior1Quantity: number; 
    Junior2ProductID: number; 
    Junior2Quantity: number; 
    Junior3ProductID: number; 
    Junior3Quantity: number; 
    SpouseEmailAddress :string;

    Exception: string;
    ReturnValue: number;     
    WasSuccessful: string;

    PrefixID: number;
    Surname: string;
    Forename: string;

    Donation : number;
}

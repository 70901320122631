import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { DatePipe } from '@angular/common';
import { Title } from "@angular/platform-browser";

import { AuthService } from '../../shared/services/auth.service';
import { AccountService } from '../../shared/services/account.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public accountList : any = [];

  constructor(private titleService: Title,private datePipe: DatePipe,private authService: AuthService,private accountService: AccountService) { 
    this.titleService.setTitle("Accounts : Member Portal");
  }

  ngOnInit() {

    if (this.authService.currentUserValue){

      var user = this.authService.currentUserValue;
      if(user  && user.UserToken !="" && user.MemberID > 0  && user.OrganisationID > 0){

        this.accountService.getMemberPayments(user.UserToken,user.OrganisationID,user.MemberID).subscribe(data => {
          console.log("Payment Info:", data)
          if(data && data.GetMemberPaymentsResult && data.GetMemberPaymentsResult.length > 0){

            for (let i = 0; i < data.GetMemberPaymentsResult.length; i++) {

              var invoiceDate = new Date(parseInt(data.GetMemberPaymentsResult[i].InvoiceDate.substr(6)));
              var invoiceDate1 = this.datePipe.transform(invoiceDate, 'dd/MM/yyyy');

              var expiryDate = new Date(parseInt(data.GetMemberPaymentsResult[i].ExpiryDate.substr(6)));
              var expiryDate1 = this.datePipe.transform(expiryDate, 'dd/MM/yyyy');

              var amount="£"+parseFloat(data.GetMemberPaymentsResult[i].Amount).toFixed(2);
              
              this.accountList.push({ PaymentID : data.GetMemberPaymentsResult[i].PaymentID , InvoiceDate: invoiceDate1,  Description: data.GetMemberPaymentsResult[i].Description, Amount : amount, ExpiryDate : expiryDate1 , PaymentTypeDescription : data.GetMemberPaymentsResult[i].PaymentTypeDescription })
            }
          }
        });

      }
    }
  }
}

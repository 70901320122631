import { Component ,OnInit} from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { ContactService } from '../../../shared/services/contact.service';

import { Contact } from '../../../shared/models/contact';

import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'site-sidemenu',
  templateUrl: './site-sidemenu.component.html',
  styleUrls: ['./site-sidemenu.component.scss']
})
export class SiteSidemenuComponent implements OnInit {

  public memberContacts = new Contact();
  bindSideMenuEvent: any;
  constructor(private authService: AuthService,private contactService: ContactService ,private sharedService : SharedService) { }

  ngOnInit() {

    this.bindMenu();
    this.bindSideMenuEvent=this.sharedService.bindSideMenuEvent.subscribe(o=>{
      this.bindMenu();
    });
  }
  ngOnDestroy() {

    this.bindSideMenuEvent.unsubscribe();
  }

  bindMenu() {
    
    if (this.authService.currentUserValue){

      var user = this.authService.currentUserValue;
      if(user &&  user.UserToken !="" && user.MemberID > 0  && user.OrganisationID > 0){

        this.contactService.getMemberContacts(user.UserToken,user.OrganisationID,user.MemberID).subscribe(data => {
          console.log('**Contact Data:', data.GetMemberContactsCCResult)
          //if(data && data.GetMemberContactsCCResult){
            this.memberContacts = data;
          //}
        });
      }
    }
  }

}

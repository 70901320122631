import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from "@angular/platform-browser";

import { AuthService } from '../../shared/services/auth.service';
import { LoginModel } from '../../shared/models/account';
import { NotificationService } from '../../shared/services/notification.service';

import { LoaderInterceptorService } from '../../shared/services/loader-interceptor.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;

  constructor(private titleService: Title, private authService: AuthService, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private notifyService: NotificationService, private noti: LoaderInterceptorService) {

    this.titleService.setTitle("Login : Member Portal");

    // redirect to home if already logged in
    if (this.authService.currentUserValue) {
      this.router.navigate(['/']);
    }

  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    let loginModel = new LoginModel();
    loginModel.emailaddress =this.f.emailAddress.value;
    loginModel.Password =this.f.password.value;

    this.authService.login(loginModel).subscribe(user => {
      
      if (user && user.UserToken && user.WasSuccessful == "OK") {

        user.MemberEmail = this.f.emailAddress.value;
        this.authService.setUser(user);
        this.router.navigate(['/']);

      } else {
        this.notifyService.showError(user.StatusDescription, "Invalid email or password");
      }

    });
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ChangeAddress } from 'src/app/shared/models/change-address';
import { MemberService } from 'src/app/shared/services/member.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-change-address',
  templateUrl: './change-address.component.html',
  styleUrls: ['./change-address.component.scss']
})
export class ChangeAddressComponent implements OnInit {

  addressForm: FormGroup;
  submitContactAddress = false;

  constructor( private authService: AuthService,private memberService: MemberService, private notifyService: NotificationService, private titleService: Title, private formBuilder: FormBuilder) {

    this.titleService.setTitle("Change Address : Member Portal");

  }

  ngOnInit() {

    this.addressForm = this.formBuilder.group({
      Address1: ['', Validators.required],
      Address2: [''],
      Address3: [''],
      Town: ['', Validators.required],
      Postcode: ['', Validators.required],
    });

    if (this.authService.currentUserValue) {
      var user = this.authService.currentUserValue;
      if(user.OrganisationID >0 && user.MemberID >0){
        this.bindMembership(user.UserToken, user.OrganisationID, user.MemberID);
      }
    }

  }

  public bindMembership(token: string, organizationId: number, memberId: number) {
    this.memberService.getMembershipDetails(token, organizationId, memberId).subscribe(data => {
    console.log("T: ", token + " O: " + organizationId + " M: " + memberId)
    console.log("Data: ", data.GetMemberCCResult[0].Address1)
    if (data && data.GetMemberCCResult && data.GetMemberCCResult[0]) {
       
        this.addressForm.setValue({
          Address1: data.GetMemberCCResult[0].Address1,
          Address2: data.GetMemberCCResult[0].Address2,
          Address3: data.GetMemberCCResult[0].Address3,
          Town: data.GetMemberCCResult[0].Town,
          Postcode: data.GetMemberCCResult[0].Postcode,
        });
        
      }
    });
  }

  // convenience getter for easy access to form fields
  get p() { return this.addressForm.controls; }

  onSubmitContactAddress() {
    this.submitContactAddress = true;

    if (this.addressForm.invalid) {
      return;
    }

    if (this.authService.currentUserValue) {
      
      var user = this.authService.currentUserValue;

      if(user.OrganisationID > 0 && user.MemberID >0){
        var changeAddress = new ChangeAddress();
        changeAddress.OrganisationID = user.OrganisationID;
        changeAddress.MemberID = user.MemberID;
        changeAddress.Address1 = this.addressForm.get('Address1').value;
        changeAddress.Address2 = this.addressForm.get('Address2').value;
        changeAddress.Address3 = this.addressForm.get('Address3').value;
        changeAddress.Town = this.addressForm.get('Town').value;
        changeAddress.Postcode = this.addressForm.get('Postcode').value;
  
        this.memberService.SetMemberAddress(changeAddress).pipe().subscribe(
          data => {
  
            if (data && data.WasSuccessful == "OK") {
              this.addressForm.markAsPristine();
              this.notifyService.showSuccess("Address update successfully.", "Member contact");
  
            } else {
              this.notifyService.showError(data.Exception, "Member contact");
            }
          });
      }

     
    }

  }

}

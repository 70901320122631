import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { MemberContact, ContactArchive } from '../../shared/models/member-contact';
import { MemberShip } from '../../shared/models/member';
import { DropDown } from '../../shared/models/drop-down';
import { ChangeAddress } from '../models/change-address';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(private http: HttpClient) { }

  getMemberships(organizationID: number, sectionID: number) {
    return this.http.get<DropDown>(`${environment.apiUrl}/GetMemberships/${organizationID}/${sectionID}`);
  }

  getMembershipDetails(token: string, organizationID: number, memberID: number) {
    return this.http.get<MemberShip>(`${environment.apiUrl}/GetMemberCC/${token}/${organizationID}/${memberID}`);
  }

  createMemberContact(memberContact: MemberContact) {

     const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    //return this.http.post<MemberContact>(`${environment.apiUrl}/SetMemberContact`, memberContact, { headers });
    return this.http.post<MemberContact>(`${environment.apiUrl}/SetMemberContact`, memberContact, { headers });
  }

  contactArchive(contactArchive: ContactArchive) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post<ContactArchive>(`${environment.apiUrl}/SetMemberContactArchive`, contactArchive, { headers });
  }

  SetMemberAddress(changeAddress : ChangeAddress){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post<ChangeAddress>(`${environment.apiUrl}/SetMemberAddress`, changeAddress, { headers });
  }

}

export class ChangeAddress {
    OrganisationID : number;
    MemberID : number;
    Address1 : string;
    Address2 : string;
    Address3 : string;
    Town : string;
    Postcode : string;
    WasSuccessful: string;
    Exception: string;
}

import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Payment } from '../models/payment';
import { MemberShip } from '../models/member-ship';
import { StripePayment, StatusPaymentStripe, StripePaymentStatusResponse, StripePaymentRefreshStatusResponse, StripePaymentStatus} from '../models/stripe-payment';


@Injectable({
  providedIn: 'root'
})
export class PaymentServiceService {

  constructor(private http: HttpClient) { }

  SetStripePayment(stripePayment: StripePayment){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post<StripePayment>(`${environment.apiUrl}/SetStripePayment`, stripePayment,{ headers });
  }

  StatusPaymentStripe(stripePayment: StatusPaymentStripe){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get<StripePaymentStatusResponse>(`${environment.apiUrl}/GetPaymentStatusV3/${stripePayment.ClientSecret}/${stripePayment.PaymentId}`, {headers});
  }

  StatusPaymentStripeRefresh(stripePayment: StatusPaymentStripe){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get<StripePaymentRefreshStatusResponse>(`${environment.apiUrl}/GetStripePaymentRefreshStatus/${stripePayment.ClientSecret}/${stripePayment.PaymentId}`, {headers});
  }
  SetStripePaymentIntent(paramsStripePayment: StripePayment){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post<StripePayment>(`${environment.apiUrl}/SetStripePaymentIntent`, paramsStripePayment,{ headers });
  }

  SetMemberStatus(stripePayment: StripePayment){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post<StripePayment>(`${environment.apiUrl}/SetMemberStatus`, stripePayment,{ headers });
  }

  SetPaymentStatus(paramsStripePaymentStatus: StripePaymentStatus){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post<StripePaymentStatus>(`${environment.apiUrl}/SetPaymentStatus`, paramsStripePaymentStatus,{ headers });
  }

  ConfirmStripePaymentIntent(stripePayment: StripePayment){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post<StripePayment>(`${environment.apiUrl}/ConfirmStripePaymentIntent`, stripePayment,{ headers });
  }

  SetPayment(payment: Payment){
    payment.PaymentTypeID = environment.PaymentTypeID;
    payment.ProductID = environment.ProductID;
    
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post<Payment>(`${environment.apiUrl}/SetPayment`, payment,{ headers });
  } 

  SetMembership(memberShip: MemberShip){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    //return this.http.post<MemberShip>(`${environment.apiUrl}/SetMembership`, memberShip,{ headers });
    return this.http.post<MemberShip>(`${environment.apiUrl}/SetMemberShip`, memberShip,{ headers });
  } 

}

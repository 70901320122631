export class Contact {
    GetMemberContactsCCResult : GetMemberContactsCCResult;
    GetMemberContactCCResult : GetMemberContactCCResult
}

export class GetMemberContactsCCResult {
    ContactID :number;
    ContactTypeDescription  : string;
    ContactTypeID : number;
    EmailAddress: string;
    Exception: null;
    Forename: string;
    MemberID: number;
    MobileNo : string;
    OrganisationID: number;
    PhoneNo: string;
    PrefixDescription: string;
    PrefixID: number;
    Surname: string;
    UserToken: null;
 }

export class GetMemberContactCCResult {
    ContactID :number;
    ContactTypeDescription  : string;
    ContactTypeID : number;
    EmailAddress: string;
    Exception: null;
    Forename: string;
    MemberID: number;
    MobileNo : string;
    OrganisationID: number;
    PhoneNo: string;
    PrefixDescription: string;
    PrefixID: number;
    Surname: string;
    UserToken: null;
 }
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';    
import { HttpClientModule , HTTP_INTERCEPTORS  } from '@angular/common/http';

import { DatePipe } from '@angular/common';
import { AuthGuardService } from './shared/guards/auth-guard.service';
import { AuthService } from './shared/services/auth.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './modules/login/login.component';
import { SiteLayoutComponent } from './modules/_layout/site-layout/site-layout.component';
import { MemberPotalComponent } from './modules/member-potal/member-potal.component';
import { RegisterComponent } from './modules/register/register.component';
import { SiteHeaderComponent } from './modules/_layout/site-header/site-header.component';
import { SiteSidemenuComponent } from './modules/_layout/site-sidemenu/site-sidemenu.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { AccountComponent } from './modules/account/account.component';
import { ContactComponent } from './modules/contact/contact.component';
import { LoadingScreenComponent } from './modules/loading-screen/loading-screen.component';

import { NewPasswordComponent } from './modules/new-password/new-password.component';
import { LoaderInterceptorService } from './shared/services/loader-interceptor.service';
import { ChangeAddressComponent } from './modules/change-address/change-address.component';
import { PaymentOKComponent } from './modules/payment-ok/payment-ok.component';
import { HelpComponent } from './modules/help/help.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SiteLayoutComponent,
    MemberPotalComponent,
    RegisterComponent,
    SiteHeaderComponent,
    SiteSidemenuComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    AccountComponent,
    ContactComponent,
    LoadingScreenComponent,
    ChangeAddressComponent,
    PaymentOKComponent,
    HelpComponent,

   
  ],
  
  imports: [
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    ToastrModule.forRoot(
      {
          closeButton: true,  
          timeOut:12000
      }), 
  ],
 
  providers: [FormsModule,AuthGuardService,AuthService,DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }

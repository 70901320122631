import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Contact } from '../models/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  getMemberContacts(token: string,organizationID : number ,memberID : number ) {
    return this.http.get<Contact>(`${environment.apiUrl}/GetMemberContactsCC/${token}/${organizationID}/${memberID}`);
  }

  getMemberContact(token: string,organizationID : number ,memberID : number ) {
    return this.http.get<Contact>(`${environment.apiUrl}/GetMemberContactCC/${token}/${organizationID}/${memberID}`);
  }

}

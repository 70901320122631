import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  getMemberPayments (token : string , organizationID :number , memberID : number ) {
    return this.http.get<any>(`${environment.apiUrl}/GetMemberPayments/${token}/${organizationID}/${memberID}`);
  }
}

import {  Injectable , Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  
  @Output() bindSideMenuEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  bindSideMenu() {
    this.bindSideMenuEvent.emit();
  }
}

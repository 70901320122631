import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { environment } from '../../environments/environment';
import { AuthService } from '../../shared/services/auth.service';
import { MemberService } from '../../shared/services/member.service';
import { PaymentServiceService } from '../../shared/services/payment-service.service';
import { GetMemberCCResult, User } from '../../shared/models/member';
import { NotificationService } from '../../shared/services/notification.service';
import { Payment } from '../../shared/models/payment';
import { MemberShip } from '../../shared/models/member-ship';
import { StripePayment } from '../../shared/models/stripe-payment';
import { DropDown } from '../../shared/models/drop-down';
import { RegisterService } from '../../shared/services/register.service';
import { MemberContact } from '../../shared/models/member-contact';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { HttpClient, HttpParams } from '@angular/common/http';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-member-potal',
  templateUrl: './member-potal.component.html',
  styleUrls: ['./member-potal.component.scss']
})
export class MemberPotalComponent implements OnInit {
  card: any;
  productMembership: string
  stripe_key = environment.stripeKey
  user:any;
  totalAmount:any;
  stripe2;
  mainContactType = 0;
  paymentForm: FormGroup;
  registerContactForm: FormGroup;
  public memberResult = new GetMemberCCResult();
  mainMembers = new DropDown();
  spouse = new DropDown();
  contactTitles = new DropDown();
  submitContactRegister = false;
  SpouseProductID: number;
  receiveMessage: any = (event: any) =>  {
    console.log(event);
  }
  cardError: string = '';
  constructor(private http:HttpClient,private paymentServiceService: PaymentServiceService, private notifyService: NotificationService, private renderer: Renderer2, private titleService: Title, private authService: AuthService, private memberService: MemberService, private formBuilder: FormBuilder, private registerService: RegisterService, private router: Router, private sharedService: SharedService) {
  renderer.listen('window', 'message', this.receiveMessage.bind(this));

    this.titleService.setTitle("Main Contact : Member Portal");

    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

  }

  setupStripe(user,totalAmount) {
 
  }
  ngOnInit() {

    this.paymentForm = this.formBuilder.group({
      Donation: [''],
      GiftAid: [''],
      Prefix: [''],
      Forename: [''],
      Surname: [''],
      TotalPayment: [''],
      PaidAmount: [''],
      AmountDue: [''],
      MainProductID: [''],
      mSubscriptionPaid: ['', Validators.required]      
    });

    this.registerContactForm = this.formBuilder.group({
      mUserToken: ['', Validators.required],
      mOrganisationID: ['', Validators.required],
      mMemberID: ['', Validators.required],
      mContactID: [''],
      mContactTypeID: ['', Validators.required],
      mPrefixID: ['', Validators.required],
      mForename: ['', [Validators.required, Validators.maxLength(40)]],
      mSurname: ['', [Validators.required, Validators.maxLength(40)]],
      mPhoneNo: ['', [Validators.maxLength(18)]],
      mMobileNo: ['', [Validators.maxLength(18)]],
      mEmailAddress: ['', [Validators.email, Validators.maxLength(80)]],
      mDonation: ['', Validators.required],
      mGiftAid: ['', Validators.required],
    });

    if (this.authService.currentUserValue) {

      var user = this.authService.currentUserValue;

      if (user  && user.OrganisationID > 0) {

        let countMembership = 0;

       
        this.memberService.getMemberships(user.OrganisationID, 1).subscribe(data => {
          if (data) {
            this.mainMembers = data;
            countMembership = countMembership + 1;
            console.log('CountMembership:' + countMembership)
            if (countMembership == 1) {
              console.log('BindMembership' + user.MemberID)
              this.bindMembership(user.UserToken, user.OrganisationID, user.MemberID);
              
            }
          }
        });

        this.resetForm(user);
      }

    }

    this.registerService.getContactTitlesList().subscribe(data => {
      if (data) {
        this.contactTitles = data;
        console.log('Contact Title List')
      }
    });

    this.initStripe();

  }

  

  private initStripe() {
    // return
    this.stripe2 = Stripe(this.stripe_key);
    // Create an instance of Elements.
    const elements = this.stripe2.elements();

    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Create an instance of the card Element.
    this.card = elements.create('card', {
      hidePostalCode: true,
      style: style
    });

    // Add an instance of the card Element into the `card - element` <div>.
    this.card.mount('#card-element');

  }

  
  public setStatusValue(token: string, organizationId: number, memberId: number) {
     this.memberService.getMembershipDetails(token, organizationId, memberId).subscribe(data => {
      if (data && data.GetMemberCCResult && data.GetMemberCCResult[0]) {
        this.memberResult.ContactStatus = data.GetMemberCCResult[0].ContactStatus;
        this.memberResult.TypeID = data.GetMemberCCResult[0].TypeID;
        this.loadScript('../assets/js/priceCal.js');
      }
    });
  }

 
  public bindMembership(token: string, organizationId: number, memberId: number) {
      this.memberService.getMembershipDetails(token, organizationId, memberId).subscribe(data => {
        console.log("T: ", token + " O: " + organizationId + " M: " + memberId)
        console.log('Member:' + data.GetMemberCCResult)
      if (data && data.GetMemberCCResult && data.GetMemberCCResult[0]) {

        this.memberResult = data.GetMemberCCResult[0];
        
        this.loadScript('../assets/js/priceCal.js');
        this.loadScript('../assets/js/datepicker.js');
      }
    });
  }

  // convenience getter for easy access to form fields
  get p() { return this.paymentForm.controls; }

  get c() { return this.registerContactForm.controls; }

  onSubmitContactRegister() {
    this.submitContactRegister = true;

    if (this.registerContactForm.invalid) {
      return;
    }

    var memberContact = new MemberContact();
    memberContact.UserToken = this.registerContactForm.get('mUserToken').value;
    memberContact.OrganisationID = this.registerContactForm.get('mOrganisationID').value;
    memberContact.MemberID = this.registerContactForm.get('mMemberID').value;
    memberContact.ContactID = this.registerContactForm.get('mContactID').value;
    memberContact.ContactTypeID = this.registerContactForm.get('mContactTypeID').value;
    memberContact.PrefixID = this.registerContactForm.get('mPrefixID').value;
    memberContact.Forename = this.registerContactForm.get('mForename').value;
    memberContact.Surname = this.registerContactForm.get('mSurname').value;
    memberContact.PhoneNo = this.registerContactForm.get('mPhoneNo').value;
    memberContact.MobileNo = this.registerContactForm.get('mMobileNo').value;
    memberContact.EMailAddress = this.registerContactForm.get('mEmailAddress').value;
    this.memberService.createMemberContact(memberContact).pipe().subscribe(
      data => {

        if (data && data.WasSuccessful == "OK") {
          this.registerContactForm.markAsPristine();
          this.notifyService.showSuccess("Member contact add successfully.", "Member contact");

          var user = this.authService.currentUserValue;
          if (user && user && user.MemberEmail != "") {
            this.resetForm(user);
            $('#addNewContactModal').modal('hide');
            this.setStatusValue(user.UserToken, user.OrganisationID, user.MemberID);
          }

          this.sharedService.bindSideMenu();

        } else {
          this.notifyService.showError(data.Exception, "Member contact");
        }
      });

  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }


  SaveMembershipAndPayment(event:any , sec: any){
    console.log(event);
    var payment = new Payment();
      payment.UserToken = this.user.UserToken;
      payment.OrganisationID = this.user.OrganisationID;
      payment.MemberID = this.user.MemberID;
      payment.InvoiceNumber = "PaymentID";
      payment.Amount = this.totalAmount;
      payment.Description = "Membership Subscription";
      payment.StatusDescription = "succeeded";
      payment.FailureCode = 0;

      this.paymentServiceService.SetPayment(payment).pipe().subscribe(
        paymentdata => {
            var memberShip = new MemberShip();
            memberShip.UserToken = payment.UserToken;
            memberShip.OrganisationID = payment.OrganisationID;
            memberShip.MemberID = payment.MemberID;
            memberShip.InvoiceNumber = payment.InvoiceNumber;
            memberShip.MainProductID = this.p.MainProductID.value;
            memberShip.PrefixID = this.p.PrefixID.value;
            memberShip.Forename = this.p.Forename.value;
            memberShip.Surname = this.p.Surname.value;
//            memberShip.Donation =  this.p.Donation.value;

            this.paymentServiceService.SetMembership(memberShip).pipe().subscribe(
              data => {
                if (data && data.WasSuccessful == "OK") {
                  this.notifyService.showSuccess("Membership payment successful.", "Success");
                  this.sharedService.bindSideMenu();
                  this.bindMembership(this.user.UserToken, this.user.OrganisationID, this.user.MemberID);
                 }
                else {
                  this.notifyService.showError("Payment transferred successfully but Membership not set in system. Please contact main office Invoice ID: ", "Set membership failed");
                }
              }
            );
        }
      );
   };


  public resetForm(user: User) {
    this.registerContactForm.setValue({
      mUserToken: user.UserToken,
      mOrganisationID: user.OrganisationID,
      mMemberID: user.MemberID,
      mContactID: "0",
      mContactTypeID: "3",
      mPrefixID: "",
      mForename: "",
      mSurname: "",
      mPhoneNo: "",
      mMobileNo: "",
      mEmailAddress: "",
      mDonation: "",
      mGiftAid: ""
    });


  }

  onChange(value) {
    this.mainContactType = parseInt(value);
  }


  async Checkout() {
    try {
      let selectProductElement = document.getElementById("ddlMainMembership")
      if (!selectProductElement) return console.error(`Not found select product`)
      console.log("Donation", this.paymentForm.controls.Donation.value)
      console.log("GiftAid", this.paymentForm.controls.GiftAid.value)
      console.log("MemberID", this.memberResult.MemberID)

      let stripePayment = new StripePayment();
      stripePayment.UserToken = this.authService.currentUserValue.UserToken;
      // stripePayment.OrganisationID = this.memberResult.OrganisationID;
      stripePayment.OrganisationID = 17;
      stripePayment.MemberID = this.memberResult.MemberID * 100 + 1;
      stripePayment.ProductID = this.productMembership;
      if (this.paymentForm.controls.Donation.value == "") {
        stripePayment.Donation = 0;
      }
      else {
        stripePayment.Donation = parseFloat(this.paymentForm.controls.Donation.value);
      }  
      if (this.paymentForm.controls.GiftAid.value == true) {
        stripePayment.GiftAid = 1;
       }
      else {
        stripePayment.GiftAid = 0;
      }




      //      stripePayment.GiftAid = this.paymentForm.controls.GiftAid.value
      console.log("Stripe Payment:", stripePayment)
      $("#MemberPayNow").hide();
      this.paymentServiceService.SetStripePaymentIntent(stripePayment).pipe().subscribe(
        async stripeData => {
          const { paymentIntent, error } = await this.stripe2.confirmCardPayment(stripeData.ClientSecret, {
            payment_method: {
              card: this.card
            },
          });

          const paymentId = paymentIntent ? paymentIntent.id : error.payment_intent.id
          const clientSecret = paymentIntent ? paymentIntent.client_secret : error.payment_intent.client_secret

          setTimeout(() => {
            this.router.navigate(['/payment-ok'] , { queryParams: {paymentId, clientSecret}});
          }, 1000)
        })
    } catch (error) {
      return { error };
    }
  }
    
}

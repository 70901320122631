import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Title } from "@angular/platform-browser";

import { AuthService } from '../../shared/services/auth.service';
import { NotificationService } from '../../shared/services/notification.service';
import { RegisterService } from '../../shared/services/register.service';

import { NewPassword } from '../../shared/models/new-password';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  newPasswordForm: FormGroup;
  submitted = false;
  newPassword = new NewPassword();

  constructor(private registerService: RegisterService, private titleService: Title, private authService: AuthService, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private notifyService: NotificationService) {

    this.titleService.setTitle("Change Password : Member Portal");

    // redirect to home if already logged in
    if (this.authService.currentUserValue) {
      this.router.navigate(['/']);
    }

  }

  ngOnInit() {

    this.newPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(12)]]
    });

    this.route.queryParams.subscribe(params => {
      this.newPassword.UserToken = params['token'];
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.newPasswordForm.controls; }

  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.newPasswordForm.invalid) {
      return;
    }

    this.newPassword.Password = this.newPasswordForm.get('password').value;

    this.registerService.NewPassword(this.newPassword).subscribe(data => {
      if (data && data.WasSuccessful) {
        if (data.WasSuccessful == "OK") {

          this.notifyService.showSuccess("Password change successfully", "Success");
          this.resetForm(this.newPasswordForm);

        } else {
          this.notifyService.showError(data.Exception, "New password");
        }
      } else {
        this.notifyService.showError(data.Exception, "New password");
      }
    });

  }

  public resetForm(formGroup: FormGroup) {
    let control: AbstractControl = null;
    formGroup.reset();
    formGroup.markAsUntouched();
    Object.keys(formGroup.controls).forEach((name) => {
      control = formGroup.controls[name];
      control.setErrors(null);
    });
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './shared/guards/auth-guard.service';

import { SiteLayoutComponent } from './modules/_layout/site-layout/site-layout.component';
import { LoginComponent } from './modules/login/login.component';
import { RegisterComponent } from './modules/register/register.component';
import { MemberPotalComponent } from './modules/member-potal/member-potal.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { AccountComponent } from './modules/account/account.component';
import { ContactComponent } from './modules/contact/contact.component';
import { NewPasswordComponent } from './modules/new-password/new-password.component';
import { ChangeAddressComponent } from './modules/change-address/change-address.component';
import { PaymentOKComponent } from './modules/payment-ok/payment-ok.component';
import { HelpComponent } from './modules/help/help.component';

const routes: Routes = [
  
  // App routes goes here here
   { 
       path: '',
       component: SiteLayoutComponent, 
       children: [
         { path: '', component: MemberPotalComponent },
         { path: 'contact/:id', component: ContactComponent },
         { path: 'account', component: AccountComponent },
         { path: 'change-address', component: ChangeAddressComponent }
       ],
       canActivate: [AuthGuardService]
   },

  //no layout routes
  { path: 'login', component: LoginComponent},
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'SetNewPassword', component: NewPasswordComponent },
  { path: 'payment-ok', component: PaymentOKComponent },
  { path: 'help', component: HelpComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export class User {

    OrganisationID :number;
    UserToken  : string;
    WasSuccessful : string;
    StatusDescription : string;
    MemberID : number;
    MemberEmail : string;
}

export class Member {
    OrganisationID :number;
    ClientName : string;
    Address1 : string;
    Address2 : string;
    Address3 : string;
    Town : string;
    Postcode : string;
    PrefixID :number;
    Forename : string;
    Surname : string;
    PhoneNo : string;
    MobileNo : string;
    EmailAddress : string;
    Password : string;
    ReturnValue : number;
    WasSuccessful : string;
    Exception :string;
}

export class MemberShip{
    GetMemberCCResult : GetMemberCCResult
}

export class GetMemberCCResult {
    Address1: string;
    Address2: string;
    Address3: string;
    ClientID: number;
    ClientName: string;
    ClientTypeID: number;
    EmailAddress: string;
    Exception: string;
    Forename: string;
    MemberID: number;
    MobileNo: string;
    OrganisationID: number;
    Password: string;
    PhoneNo: string;
    Postcode: string;
    PrefixDescription: string;
    PrefixID: number;
    StatusDescription: string;
    Surname: string;
    Town: string;
    TypeDescription: string;
    TypeID: number;
    UserToken: string;

    ContactStatus: string;
    MainProductID:number;  
  
    AmountPaid : number;
    AmountDue : number;
    Donation : number;
}
export class Account {
    PaidDate: string;
    Description: string;
    Amount: number;
    ExpiryDate: string;
    CategoryDescription: string;
}

export class LoginModel {

    emailaddress: string;
    Password: string;
    
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { MemberService } from 'src/app/shared/services/member.service';
import { PaymentServiceService } from '../../shared/services/payment-service.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StripePaymentStatus } from '../../shared/models/stripe-payment';

@Component({
  selector: 'app-payment-ok',
  templateUrl: './payment-ok.component.html',
  styleUrls: ['./payment-ok.component.scss']
})
export class PaymentOKComponent implements OnInit {
  private statusPayment: string
  private amountPayment: string
  private currencyPayment: string
  private idPayment: string
  private datePayment: string

  constructor(
    private authService: AuthService,
    private memberService: MemberService,
    private paymentServiceService: PaymentServiceService,
    private router: Router,
    private router2: ActivatedRoute,
    private notifyService: NotificationService,
    private titleService: Title,
    private formBuilder: FormBuilder) {

    this.titleService.setTitle("Payment OK : Member Portal");

  }

  private formatDate(dateString: string): string {
    const regex = /\/Date\((\d+)([-+])(\d{2})(\d{2})\)\//
    const match = dateString.match(regex)
    const timestamp = parseInt(match[1])
    const offsetSign = match[2]
    const offsetHours = parseInt(match[3])
    const offsetMinutes = parseInt(match[4])
    
    let timezoneOffset = ((offsetHours * 60) + offsetMinutes) * 60 * 1000
    offsetSign === '-' && ( timezoneOffset *= -1)

    const date = new Date(timestamp + timezoneOffset)
    return date.toLocaleString().replace(/\//ig, "-")
  }

  private getStatusPayment() {
    let parameters = this.router2.snapshot.queryParamMap

    this.paymentServiceService.StatusPaymentStripe({
      ClientSecret: parameters.get("clientSecret") as string,
      PaymentId: parameters.get("paymentId") as string
    }).pipe().subscribe(
      paymentdata => {
        console.log("Payment Data: ", {paymentdata})
        if (!paymentdata || !paymentdata.GetPaymentStatusV3Result) return this.statusPayment = "fail"

        const { StatusDescription, InvoiceDate, Currency, Amount, InvoiceNumber } = paymentdata.GetPaymentStatusV3Result

        this.statusPayment = ['charge.succeeded', 'payment_intent.succeeded', 'Pass'].includes(StatusDescription) ? "succeeded" : ['payment_intent.payment_failed'].includes(StatusDescription) ? "fail" : "pending"
        this.datePayment = this.formatDate(InvoiceDate)
        this.currencyPayment = Currency
        this.amountPayment = Amount.toFixed(2)
        this.idPayment = InvoiceNumber
      })
  }

  private getRefreshedStatusPayment() {
    let parameters = this.router2.snapshot.queryParamMap

    this.paymentServiceService.StatusPaymentStripeRefresh({
      ClientSecret: parameters.get("clientSecret") as string,
      PaymentId: parameters.get("paymentId") as string
    }).pipe().subscribe(
      paymentdata => {
        console.log("Payment Data: ", {paymentdata})
        if (!paymentdata || !paymentdata.GetStripePaymentRefreshStatusResult) return this.statusPayment = "fail"

        const { StatusDescription, InvoiceDate, Currency, Amount, InvoiceNumber } = paymentdata.GetStripePaymentRefreshStatusResult

        this.statusPayment = ['charge.succeeded', 'payment_intent.succeeded', 'Pass'].includes(StatusDescription) ? "succeeded" : ['payment_intent.payment_failed'].includes(StatusDescription) ? "fail" : "pending"
        this.datePayment = this.formatDate(InvoiceDate)
        this.currencyPayment = Currency
        this.amountPayment = Amount.toFixed(2)
        this.idPayment = InvoiceNumber
      })
  }

  private SetPaymentStatus() {
    console.log("Start Payment Status:")
    let parameters = this.router2.snapshot.queryParamMap   
    var PaymentStatus = new StripePaymentStatus();
    PaymentStatus.OrganisationID = 17;
    PaymentStatus.ClientSecret = parameters.get("clientSecret");
    PaymentStatus.PaymentIntentId = parameters.get("paymentId");
    PaymentStatus.StatusID = 1;
    console.log("Payment Status:", {PaymentStatus})
 
    this.paymentServiceService.SetPaymentStatus(PaymentStatus).pipe().subscribe(
      paymentstatusdata => {
        console.log("Payment Status Data: ", {paymentstatusdata})
      }
    )

  } 
 
  ngOnInit() {
    this.getStatusPayment();
    this.SetPaymentStatus();    
  }
  
  onSubmitReturnToPortal() {
    console.log("Return")
     this.router.navigate(['/member-potal']);
  }

  onSubmitRefresh() {
    console.log("Refresh")
    this.getRefreshedStatusPayment();
  }

}

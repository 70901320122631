import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormBuilder, FormGroup, Validators ,AbstractControl } from '@angular/forms';
import { Title } from "@angular/platform-browser";

import { AuthService } from '../../shared/services/auth.service';
import { RegisterService } from '../../shared/services/register.service';

import { NotificationService } from '../../shared/services/notification.service';
import { DropDown} from '../../shared/models/drop-down';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  contactTitles = new DropDown();

  constructor(private titleService: Title,private notifyService : NotificationService,private registerService: RegisterService,private authService: AuthService,private formBuilder: FormBuilder,private route: ActivatedRoute,private router: Router) { 

    this.titleService.setTitle("Register : Member Portal");

    // redirect to home if already logged in
    if (this.authService.currentUserValue) {
      this.router.navigate(['/']);
    }

  }

  ngOnInit() {

    this.registerForm = this.formBuilder.group({
      ClientName : ['', [Validators.required,Validators.maxLength(40)]],
      PrefixID : ['', Validators.required],
      Forename : ['', [Validators.required,Validators.maxLength(40)]],
      Surname : ['', [Validators.required,Validators.maxLength(40)]],
      PhoneNo : ['', [Validators.maxLength(18)]],
      MobileNo : ['', [Validators.maxLength(18)]],
      EmailAddress : ['', [Validators.required, Validators.email, Validators.maxLength(80)]],
      Address1: ['', [Validators.required,Validators.maxLength(60)]],
      Address2: ['',Validators.maxLength(60)],
      Address3 : ['',Validators.maxLength(60)],
      Town : ['', [Validators.required ,Validators.maxLength(60) ]],
      Postcode : ['', [Validators.required,Validators.maxLength(12)]],
      Password : ['', [Validators.required, Validators.minLength(8),Validators.maxLength(20)]]
    });

    this.registerService.getContactTitlesList().subscribe(data => {
      if(data){
        this.contactTitles = data;
      }
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.registerForm.invalid) {
          return;
      }
      
      this.registerService.create(this.registerForm.value).pipe().subscribe(
      data => {

          if(data && data.ReturnValue > 0 && data.WasSuccessful == "OK"){
            this.registerForm.markAsPristine();
            this.notifyService.showSuccess("Registration Successful, Please now log in and pay.","Register successfully");
            
            this.resetForm(this.registerForm);

          }else{
            this.notifyService.showError(data.Exception,"Registration failed");
          }
      });
  }

  public resetForm(formGroup: FormGroup) {
    let control: AbstractControl = null;
    formGroup.reset();
    formGroup.markAsUntouched();
    Object.keys(formGroup.controls).forEach((name) => {
      control = formGroup.controls[name];
      control.setErrors(null);
    });
  }

}

export class StripePayment {
   UserToken: string;
   OrganisationID: number;
   MemberID: number;
   ProductID: string;
   Token: string;
   Amount: number;
   Donation: number;
   GiftAid: number;
   InvoiceNumber: string;
   ClientSecret: string;
   Description: string;
   
   Exception: string;
   ReturnValue: number;
   WasSuccessful: string;

}

export class StatusPaymentStripe {
   PaymentId: string;
   ClientSecret: string;
}

export class StripePaymentStatus {
   UserToken: string;
   OrganisationID: number;
   PaymentIntentId: string;
   ClientSecret: string;
   StatusID: number;
 
   Exception: string;
   ReturnValue: number;
   WasSuccessful: string;   
}

export class DataStripePaymentStatusResponse {
   Amount: number
   ClientSecret: string
   Currency: string
   Exception: string
   FailureCode: number
   InvoiceDate: string
   InvoiceNumber: string
   MemberID: number
   OrganisationID: number
   PaymentDescription: string
   PaymentIntentID: number
   ProductID: number
   StatusDescription: string
   Token: string
   UserToken: string
}

export class StripePaymentStatusResponse {
   GetPaymentStatusV3Result: DataStripePaymentStatusResponse
}

export class DataStripePaymentRefreshStatusResponse {
   Amount: number
   ClientSecret: string
   Currency: string
   Exception: string
   FailureCode: number
   InvoiceDate: string
   InvoiceNumber: string
   MemberID: number
   OrganisationID: number
   PaymentDescription: string
   PaymentIntentID: number
   ProductID: number
   StatusDescription: string
   Token: string
   UserToken: string
}

export class StripePaymentRefreshStatusResponse {
   GetStripePaymentRefreshStatusResult: DataStripePaymentRefreshStatusResponse
}

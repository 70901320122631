import { DatePipe } from '@angular/common';

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Title } from "@angular/platform-browser";

declare var jquery: any;
declare var $: any;

import { AuthService } from '../../shared/services/auth.service';
import { RegisterService } from '../../shared/services/register.service';
import { NotificationService } from '../../shared/services/notification.service';
import { ContactService } from '../../shared/services/contact.service';
import { GetMemberContactCCResult } from '../../shared/models/contact';

import { MemberService } from '../../shared/services/member.service';
import { ChangePassword } from '../../shared/models/change-password';
import { ContactArchive } from '../../shared/models/member-contact';

import { DropDown } from '../../shared/models/drop-down';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {

  registerForm: FormGroup;

  changePasswordForm: FormGroup;
  public contact = new GetMemberContactCCResult();

  contactTitles = new DropDown();
  schools = new DropDown();
  public yearGroups = new DropDown();

  memberId = 0;
  memberContactId = 0;
  contactTypeID = 0;
  mainContactType = 0;
  contactTypeDescription = '';
  submitchangePassword = false;
  submitregister = false;

  constructor(private titleService: Title, private memberService: MemberService, private datePipe: DatePipe, private contactService: ContactService, private authService: AuthService, private notifyService: NotificationService, private registerService: RegisterService, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private sharedService: SharedService) {

    this.titleService.setTitle("Contact Details : Member Portal");

    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

  }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      Password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(12)]],
    });

    this.registerForm = this.formBuilder.group({
      UserToken: ['', Validators.required],
      OrganisationID: ['', Validators.required],
      MemberID: ['', Validators.required],
      ContactID: ['', Validators.required],
      ContactTypeID: ['', Validators.required],
      PrefixID: ['', Validators.required],
      Forename: ['', [Validators.required, Validators.maxLength(40)]],
      Surname: ['', [Validators.required, Validators.maxLength(40)]],
      PhoneNo: ['', [Validators.maxLength(18)]],
      MobileNo: ['', [Validators.maxLength(18)]],
      EmailAddress: ['', [Validators.email, Validators.maxLength(80)]]

    });

    this.registerService.getContactTitlesList().subscribe(data => {
      if (data) {
        this.contactTitles = data;
      }
    });

    if (this.authService.currentUserValue) {

      var user = this.authService.currentUserValue;

      if (user && user.OrganisationID > 0) {

        var contactID = this.route.snapshot.params.id;
        if (contactID > 0) {

          this.contactService.getMemberContact(user.UserToken, user.OrganisationID, contactID).subscribe(data => {
            if (data && data.GetMemberContactCCResult && data.GetMemberContactCCResult[0]) {

              let contactType;

              if (data.GetMemberContactCCResult[0].ContactTypeID > 0) {
                contactType = data.GetMemberContactCCResult[0].ContactTypeID;
              } else {
                contactType = '';
              }

              this.registerForm.setValue({

                UserToken: user.UserToken,
                OrganisationID: user.OrganisationID,
                MemberID: contactID,
                ContactID: data.GetMemberContactCCResult[0].ContactID,
                ContactTypeID: contactType,
                PrefixID: data.GetMemberContactCCResult[0].PrefixID,
                Forename: data.GetMemberContactCCResult[0].Forename,
                Surname: data.GetMemberContactCCResult[0].Surname,
                PhoneNo: data.GetMemberContactCCResult[0].PhoneNo,
                MobileNo: data.GetMemberContactCCResult[0].MobileNo,
                EmailAddress: data.GetMemberContactCCResult[0].EmailAddress
                
              });

              this.contactTypeID = data.GetMemberContactCCResult[0].ContactTypeID;
              this.memberId = contactID;
              this.memberContactId = data.GetMemberContactCCResult[0].ContactID;
              this.mainContactType = this.contactTypeID;

              if (this.contactTypeID == 1) {
                this.contactTypeDescription = 'Main Contact';

                const emailAddress = this.registerForm.get('EmailAddress');
                emailAddress.setValidators([Validators.required]);

              } else if (this.contactTypeID == 2) {
                this.contactTypeDescription = 'Spouse';
              } else if (this.contactTypeID == 3) {
                this.contactTypeDescription = 'Junior';
              }
              this.loadScript('../assets/js/datepicker.js');
            }
          });

        }
      }
    }
  }

  // convenience getter for easy access to form fields
  get p() { return this.changePasswordForm.controls; }

  onSubmitChangepassword() {
    this.submitchangePassword = true;

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }

    if (this.authService.currentUserValue) {

      var user = this.authService.currentUserValue;
      var contactID = this.route.snapshot.params.id;

      if (this.memberContactId > 0 && user && user.OrganisationID > 0 && user.MemberID > 0 && contactID > 0) {

        var changePassword = new ChangePassword();

        changePassword.UserToken = user.UserToken;
        changePassword.OrganisationID = user.OrganisationID;
        changePassword.MemberID = contactID;
        changePassword.ContactID = this.memberContactId;
        changePassword.Password = this.changePasswordForm.get('Password').value;

        this.registerService.changePassword(changePassword).pipe().subscribe(
          data => {

            if (data && data.WasSuccessful == "OK") {
              this.changePasswordForm.markAsPristine();
              this.notifyService.showSuccess("Password change successfully.", "Password change");

              this.resetForm(this.changePasswordForm);

              $('#changePasswordModal1').modal('hide');

            } else {
              this.notifyService.showError(data.Exception, "Password change");
            }

          });

      } else {
        this.notifyService.showSuccess("Data missing.", "Password change");
      }

    } else {
      this.notifyService.showSuccess("Data missing.", "Password change");
    }

  }

  get r() { return this.registerForm.controls; }

  onSubmitRegister() {

    this.submitregister = true;

    if (this.registerForm.invalid) {
      return;
    }

    this.memberService.createMemberContact(this.registerForm.value).pipe().subscribe(
      data => {

        if (data && data.WasSuccessful == "OK") {
          this.notifyService.showSuccess("Member contact update successfully.", "Member contact");
          this.sharedService.bindSideMenu();

        } else {
          this.notifyService.showError(data.Exception, "Member contact");
        }
      });

  }

  onContactArchive() {

    if (confirm("Are you sure to Archive")) {

      if (this.authService.currentUserValue) {

        var user = this.authService.currentUserValue;
        var contactID = this.route.snapshot.params.id;

        if (this.memberContactId > 0 && user && user.UserToken && user.MemberID && user.OrganisationID && contactID > 0) {

          var contactArchive = new ContactArchive();

          contactArchive.UserToken = user.UserToken;
          contactArchive.OrganisationID = user.OrganisationID;
          contactArchive.MemberID = contactID;
          contactArchive.ContactID = this.memberContactId;

          this.memberService.contactArchive(contactArchive).pipe().subscribe(
            data => {

              if (data && data.WasSuccessful == "OK") {
                this.notifyService.showSuccess("Success.", "Contact Archive");
                this.router.navigate(['']);
              } else {
                this.notifyService.showError(data.Exception, "Contact Archive");
              }

            });

        } else {
          this.notifyService.showError("Data missing", "Member Contact Archive failed");
        }

      } else {
        this.notifyService.showError("Data missing", "Member Contact Archive failed");
      }
    }
  }

  public resetForm(formGroup: FormGroup) {
    let control: AbstractControl = null;
    formGroup.reset();
    formGroup.markAsUntouched();
    Object.keys(formGroup.controls).forEach((name) => {
      control = formGroup.controls[name];
      control.setErrors(null);
    });
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  DOBonKeyUp(event: any) {
    this.registerForm.controls['DOB'].setValue($("#dobChange").val());
  };

  onChange(value) {
    this.mainContactType = parseInt(value);
  }

}

import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormBuilder, FormGroup, Validators,AbstractControl } from '@angular/forms';
import { Title } from "@angular/platform-browser";

import { AuthService } from '../../shared/services/auth.service';
import { NotificationService } from '../../shared/services/notification.service';

import { RegisterService } from '../../shared/services/register.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotForm: FormGroup;
  submitted = false;
  returnUrl: string;

  constructor(private registerService: RegisterService,private titleService: Title,private authService: AuthService, private route: ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private notifyService : NotificationService) { 

    this.titleService.setTitle("Forgot Password : Member Portal");

     // redirect to home if already logged in
     if (this.authService.currentUserValue) {
      this.router.navigate(['/']);
    }
    
  }

  ngOnInit() {

    this.forgotForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]]
   });

   // get return url from route parameters or default to '/'
   this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotForm.controls; }

  onSubmit() {

   this.submitted = true;

    // stop here if form is invalid
    if (this.forgotForm.invalid) {
        return;
    }

    this.registerService.forgotPassword(this.forgotForm.get('emailAddress').value).subscribe(data => {
      if(data && data.ForgottenPasswordCanineConcernResult ){
        console.log('Forgotten Password:', data.ForgottenPasswordCanineConcernResult)
        if(data.ForgottenPasswordCanineConcernResult.WasSuccessful =="OK"){
          this.notifyService.showSuccess(data.ForgottenPasswordCanineConcernResult.StatusDescription,"Success");
         
          this.resetForm(this.forgotForm);

        }else{
          this.notifyService.showError(data.ForgottenPasswordCanineConcernResult.StatusDescription,"Forgot password");
        }
      }else{
        this.notifyService.showError("Email not found","Forgot password");
      }
    });

 }

 public resetForm(formGroup: FormGroup) {
  let control: AbstractControl = null;
  formGroup.reset();
  formGroup.markAsUntouched();
  Object.keys(formGroup.controls).forEach((name) => {
    control = formGroup.controls[name];
    control.setErrors(null);
  });
}

}

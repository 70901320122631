import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Member } from '../models/member';
import { ChangePassword } from '../models/change-password';
import { ForgotPassword } from '../models/forgot-password';

import { DropDown } from '../models/drop-down';
import { NewPassword } from '../models/new-password';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  getContactTitlesList() {
    return this.http.get<DropDown>(`${environment.apiUrl}/GetContactTitles/` + environment.OrganisationID);
  }

  getGetSchoolList(organisationID: number) {
    return this.http.get<DropDown>(`${environment.apiUrl}/GetSchools/${organisationID}`);
  }

  getYearGroupList(organisationID: number) {
    return this.http.get<DropDown>(`${environment.apiUrl}/GetYearGroups/${organisationID}`);
  }

  create(member: Member) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    member.OrganisationID = environment.OrganisationID;
    return this.http.post<Member>(`${environment.apiUrl}/SetMemberRegistrationCanineConcern`, member, { headers });
  }

  changePassword(changePassword: ChangePassword) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post<ChangePassword>(`${environment.apiUrl}/SetNewPasswordCanineConcernWithToken`, changePassword, { headers });
  }

  forgotPassword(email: string) {
    return this.http.get<ForgotPassword>(`${environment.apiUrl}/ForgottenPasswordCanineConcern/${email}`);
  }

  NewPassword(newPassword: NewPassword) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    newPassword.OrganisationID = environment.OrganisationID;
    return this.http.post<NewPassword>(`${environment.apiUrl}/SetNewPasswordCanineConcernWithToken`, newPassword, { headers });
  }

}

import { Component, OnInit , OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoadingScreenService } from '../../shared/services/loading-screen.service';
import { LoaderState } from '../../shared/interface/loader';

import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit , OnDestroy {

  show = false;
  private subscription: Subscription;
 
  constructor(private authService: AuthService,private loaderService: LoadingScreenService) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
    .subscribe((state: LoaderState) => {
      this.show = state.show;
    });
  }
  
  ngOnDestroy() {

    if (!this.authService.currentUserValue) {
      this.subscription.unsubscribe();
    }
    
  }

}

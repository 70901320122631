export class DropDown {
    GetContactTitlesResult:GetContactTitlesResult;
    GetSchoolsResult:GetSchoolsResult;
    GetYearGroupsResult:GetYearGroupsResult;
    GetMembershipsResult:GetMembershipsResult;
}

export class GetContactTitlesResult{
    iTypeID : number;
    sTypeDescription : string;
}

export class GetSchoolsResult {
    iTypeID : number;
    sTypeDescription : string;
}

export class GetYearGroupsResult{
    iTypeID : number;
    sTypeDescription : string;
}

export class GetMembershipsResult{
    iTypeID : number;
    sTypeDescription : string;
}
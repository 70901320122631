export class MemberContact {

    ContactID: number;
    ContactTypeID: number;
    ContactTypeDescription: string;
    EMailAddress: string;
    Exception: string;
    Forename: string;
    MemberID: number;
    MobileNo: string;
    OrganisationID: number;
    PhoneNo: string;
    PrefixDescription: string;
    PrefixID: number;
    Surname: string;
    UserToken: string;
    ReturnValue: number;
    WasSuccessful: string;
}

export class ContactArchive {
    UserToken: string;
    OrganisationID: number;
    MemberID: number;
    ContactID: number;
    Exception: string;
    ReturnValue: number;
    WasSuccessful: string;
}

export class Payment {

    UserToken: string;
    OrganisationID: number;
    MemberID: number; 
    InvoiceNumber: string;
    Amount: number; 
    PaymentTypeID:  number; 
    ProductID:  number;  
    Description: string;
    FailureCode:  number; 
    StatusDescription: string;

    Exception: string;
    ReturnValue: number;    
    WasSuccessful: string;
    ClientSecret : string;
}
